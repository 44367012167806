@charset "UTF-8";

$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Slider */

.slick-list {
  .slick-loading & {
    background: #fff;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;

  @include breakpoint(small) {
    height: 65px;
    width: 65px;
  }

  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-size: 20px;
    line-height: 1;
    color: $slick-arrow-color;
    opacity: $slick-opacity-default;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -10px;
  @include breakpoint(small) {
    left: -17px;
    [dir="rtl"] & {
      left: auto;
      right: -17px;
    }
    &:before {
      content: "";
      [dir="rtl"] & {
        content: "";
      }
    }
  }
}

.slick-next {
  right: -10px;
  @include breakpoint(small) {
    right: -17px;
    [dir="rtl"] & {
      left: -17px;
      right: auto;
    }
    &:before {
      content: "";
      [dir="rtl"] & {
        content: "";
      }
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;
      &:hover, &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-size: $slick-dot-size;
        line-height: 20px;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      color: $slick-dot-color-active;
      opacity: $slick-opacity-default;
    }
  }
}

.slick-list {

  @include breakpoint(small) {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }

  @include breakpoint(medium) {
    padding-top: 53px !important;
    padding-bottom: 53px !important;
  }

  @include breakpoint(large) {
    padding-top: 73px !important;
    padding-bottom: 73px !important;
  }
}

///* the slides */
//.slick-slide {
//  margin: 0 10px;
//}
//
///* the parent */
//.slick-list {
//  margin: 0 -10px;
//}

.gallery {
  .polygon, .arrow { transition: fill .3s; }
  .polygon { fill: #FFE600; }
  .arrow { fill: #323232; }

  .slick-arrow:hover {
    .polygon { fill: palette(brand, purple); }
    .arrow { fill: white; }
  }

  @include breakpoint(small) {
    img {
      transition: transform .3s;
      border-left: 10px solid white;
      border-right: 10px solid white;
    }

    .slick-current img { transform: scale(1.5) }
  }
}