/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/

.g {
  width: 100%;
  lost-flex-container: row;

  > * { width: 100%; }
}

.g--ng {
  > * {
    width: 50%;
    flex-basis: 50%;
  }
}

.g__1 {
}

.g__ac { align-items: center; }

.g__1-2 { lost-column: 1/2; }

/*
  Footer grid modification
*/
.g--footer {
}

.g--gutter {
  .g__1-2 { lost-column: 1/2; }
  .g__1-3 { lost-column: 1/3 3 gutter(1) flex; }
  .g__2-3 { lost-column: 2/3 3 gutter(1) flex; }
}

.g__ma {
  margin-left: auto !important;
  margin-right: auto !important;
}

@include breakpoint(small) {

  .gs__1-3 { lost-column: 1/3; }
  .gs__2-3 { lost-column: 2/3; }

  .g--gutter {
    .gs__1-2 { lost-column: 1/2 2 gutter(1); }
  }

  .g--gutter-lg {
    .gs__1-2 { lost-column: 1/2 2 gutter(6) flex; }
    .gs__1-3 { lost-column: 1/3 3 gutter(6) flex; }
  }
}

@include breakpoint(medium) {

  .gm__ac { align-items: center; }

  .g--gutter {
    .gm__1-2 { lost-column: 1/2 2 gutter(2); }

    .gm__1-3 { lost-column: 1/3 3 gutter(2); }
  }

  .g--gutter-lg {
    .gm__1-2 { lost-column: 1/2 2 gutter(6); }

    .gm__1-3 { lost-column: 1/3 3 gutter(6); }

    .gm__1-5 { lost-column: 1/5 5 gutter(6) flex; }
  }

  .gm__1-2 { lost-column: 1/2; }
}

@include breakpoint(large) {

  .g--gutter {
    .gl__1-3 { lost-column: 1/3 3 gutter(2); }
    .gl__1-4 { lost-column: 1/4 4 gutter(2) flex; }
  }

  .g--gutter-lg {
    .gl__1-4 { lost-column: 1/4 4 gutter(6) flex; }
  }

  .gl__1-3 { lost-column: 1/3 0 0 flex; }

  .g--row-1 {
    .gl__mod {
      &:first-of-type { lost-column: 4/10 10 gutter(2); }
      &:last-of-type { lost-column: 6/10 10 gutter(2); }
    }
  }

  .g--row-2 {
    .gl__mod {
      &:first-of-type { lost-column: 6/10 10 gutter(2); }
      &:last-of-type { lost-column: 4/10 10 gutter(2); }
    }
  }
}
