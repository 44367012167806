$palettes: (
        black: (
                base: black,
                text: #333333,
                hover: #989896,
                footer: #2b2b2b
        ),
        grey: (
                light: #ededed,
                base: #5f5f5f,
                dark: #d7d7d7
        ),
        blue: (
                base: #32a1b2,
                hover: #2b8e9d
        ),
        green: (
                base: #32b292
        ),
        red: (
                base: #ed2f2f,
                error: #EE3D52
        ),
        brand: (
                pink: #ff00cc,
                purple: #27005c,
                red: #ea3457,
                blue: #00e7ff,
                yellow: #ffe600
        )
) !global;

// ==========================================================================
// COLOR PALETTE FUNCTION
// ==========================================================================
// Example:
//
// a {
//     color: palette(yellow, light);
// }
//

@function palette($palette, $tone: 'base') {
  @return map-get(map-get($palettes, $palette), $tone);
}