.body {

  //p:last-of-type { margin-bottom: 0; }

  .h3 { margin: 35px 0 20px; }

  .image, .video { margin: 10px 0 20px; }

  .video {
    iframe {
      width: 100%;
      height: 320px;
    }
    @include breakpoint(medium) {

      iframe {
        height: 450px;
      }
    }
  }
}

.video--large {
  iframe {
    width: 100%;
    height: 320px;
  }

  @include breakpoint(medium) {
    iframe { height: 650px; }
  }
}

.image {
  font-size: 0;
  figcaption {
    background-color: palette(brand, red);
    padding: 10px gutter(1);
    color: white;
    font-size: 1.6rem;
    font-weight: bold;
  }
}

.pusher {
  padding-left: 35px;
  position: relative;
  &:before {
    content: "";
    height: 3px;
    border-radius: 25px;
    width: 25px;
    background-color: palette(brand, red);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.img--shape {
  margin: 0 20px 40px;
  position: relative;
  img { position: relative; }

  &:before {
    content: "";
    position: absolute;
    width: 110%;
    height: 110%;
    top: 50%;
    left: 50%;
    background-color: palette(brand, yellow);
    clip-path: polygon(15% 0, 95% 5%, 100% 90%, 20% 100%, 0 70%);
    transform: translateX(-50%) translateY(-50%);
  }

  &-2 {
    &:before {
      background-color: palette(brand, red);
      clip-path: polygon(9% 10%, 94% 0, 100% 100%, 8% 93%, 0 58%);
    }
  }

  @include breakpoint(medium) { margin: 0; }
}

.stats {
  margin-bottom: 40px;
  &:last-of-type { margin-bottom: 0; }

  span {
    display: block;
    font-weight: bold;
    margin-bottom: 10px;
    &:last-of-type { margin-bottom: 0; }
  }

  .x-big-text, .big-text { font-family: $alt-font; }

  .x-big-text { font-size: 7rem; }
  .big-text { font-size: 4.2rem; }
  .text { font-size: 2.6rem; }

  @include breakpoint(small) { margin-bottom: 0; }
}

.icon-hover {
  margin-bottom: 40px;

  a {

    .icon, .text {
      transition: transform .3s;
      display: block;
      -webkit-backface-visibility: hidden;
    }



    .text { margin-top: 30px; }

    &:hover {
      .text { transform: translateZ(0) scale(1.2) }

      .icon {
        transform: scale(1.1);
      }
    }
  }

  @include breakpoint(medium) { margin-bottom: 0; }
}

.quote {

  div, p { position: relative; z-index: 1; }

  &:before, &:after {
    content: "";
    position: absolute;
    top: -10px;
    width: 40px;
    height: 27px;
    background-repeat: no-repeat;
  }

  &:before {
    left: 10px;
    background-image: url("/dist/img/icons/quote-left.svg");
  }

  &:after {
    right: 10px;
    background-image: url("/dist/img/icons/quote-right.svg");
  }

  .x-big p {
    font-size: $p-size--big;
    @include breakpoint(small) { font-size: 3rem; }
  }

  @include breakpoint(small) {
    &:before, &:after {
      top: -10px;
      width: 80px;
      height: 50px;
    }

    &:before { left: -80px; }
    &:after { right: -80px; }
  }
}

.extra-cs {

  .half {
    @include bg-cover();
  }

  a {
    display: block;
    position: relative;
    color: palette(brand, red);
    font-size: 3rem;
    path { fill: white; }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: black;
      opacity: .6;
      transition: opacity .3s;
    }

    &:hover {
      color: white;
      &:before { opacity: .35; }
      .link-block { background-color: palette(brand, red); }
    }
  }

  .inner {
    position: relative;
    padding: 150px gutter(1) 30px;
  }

  .link-block {
    display: inline-block;
    padding: 10px;
    transition: background-color .3s;
  }

  @include breakpoint(small) {
    display: flex;

    .half {
      flex: 1;
      &:last-of-type { text-align: right; }
    }

    .inner {
      position: relative;
      padding: 360px gutter(3) 50px;
    }
  }
}