// ==========================================================================
// #ICONS
// ==========================================================================
.icon {
  @include bg-cover();
  display: inline-block;
  vertical-align: middle;
  background-size: contain;
  > svg { display: block; }
  path, polygon { transition: fill .3s; }
}

// ==========================================================================
// #ICONS-COLORS
// ==========================================================================
.icon--white {
  path { fill: white; }
}

.icon--black {
  path, polygon { fill: black !important; }
}

// Colors
@each $color, $shades in $palettes {
  $colorName: if($color == grey, '-', '--#{$color}');
  @each $shade, $value in $shades {
    $shadeModifier: if($shade == base, '', '-#{$shade}');

    @if $shade == base {
      $shadeModifier: if($color == grey, '-#{$color}', '');
    } @else {
      $shadeModifier: '-#{$shade}';
    }

    .icon#{$colorName}#{$shadeModifier} {
      path, polygon, rect, circle {
        fill: palette($color, $shade);
      }
    }
  }
}

// ==========================================================================
// #ICONS-SIZE
// ==========================================================================

.icon--20 {
  &,
  & > svg {
    width: 20px;
    height: 20px;
  }
}

.icon--25 {
  &,
  & > svg {
    width: 25px;
    height: 25px;
  }
}

.icon--30 {
  &,
  & > svg {
    width: 30px;
    height: 30px;
  }
}

.icon--40 {
  &,
  & > svg {
    width: 40px;
    height: 40px;
  }
}

.icon--60 {
  &,
  & > svg {
    width: 60px;
    height: 60px;
  }
}

.icon--service {
  display: block;
  margin-right: auto;
  margin-left: auto;
  &,
  & > svg {
    width: 100px;
    height: 100px;
  }

  @include breakpoint(large) {
    &,
    & > svg {
      width: 130px;
      height: 130px;
    }
  }
}

.icon--logo {
  &,
  & > svg {
    width: 90px;
    height: 46px;
    path { fill: white; }

    @include breakpoint(medium) {
      width: 150px;
      height: 77px;
    }
  }
}

.icon--footer-logo {
  &,
  & > svg {
    width: 120px;
    height: 62px;
  }
}

// ==========================================================================
// #ICONS-SOCIAL
// ==========================================================================
.icon--social {
  &,
  & > svg {
    width: 60px;
    height: 60px;
  }

  svg > *.bkg { fill: palette(brand, purple) }
  svg > *.social { fill: white }
  svg { overflow: visible; }
  polygon, path, circle {
    transition: transform .3s;
    transform-origin: center;
  }
  &:hover polygon { transform: scale(1.2) rotate(30deg); }
  &:hover { path, circle { transform: scale(0.8); }}
}

a:hover {
  .icon--facebook svg > *.bkg { fill: #3b5998; }
  //.icon--twitter svg > * { fill: #00b6f1; }
  .icon--insta svg > *.bkg { fill: #c32aa3 }
  //.icon--mail svg > * { fill: black; }
}