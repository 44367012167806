// ==========================================================================
// #HEADER
// ==========================================================================
#header {
  position: fixed;
  z-index: 9;
  width: 100%;

  .logo {
    position: relative;
    z-index: 999;
    display: inline-block;
    padding: 20px 30px 30px gutter(1);
    transition: background .3s;
    clip-path: polygon(85% 0, 100% 55%, 55% 100%, 0 85%, 0 0);

    img { max-width: 60px; }
  }

  &.logo-bkg, &.is-open {
    .logo {
      background: white;
      svg path { fill: #1D1D1B; }
    }
  }

  .shape, .navigation {
    position: absolute;
  }

  .shape {
    top: 0;
    right: 0;
    z-index: 998;
    width: 40px;
    transition: transform .3s;

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      transition: all .5s ease-in-out;
    }

    &:before {
      top: -20px;
      right: -5px;
      height: 90px;
      width: 90px;
      background-image: url('/dist/img/icons/ieffects-polygon.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }

    &:after {
      top: 0;
      right: 0;
      height: 50px;
      width: 50px;
      background-color: white;
    }
  }

  .navigation {
    z-index: 1000;
    top: gutter(1);
    right: gutter(1);
    display: flex;

    .navigation__burger {
      position: relative;
      color: white;
    }

    &:hover {
      .navigation__burger {
      }

      ~ .shape {
        transform: scale(1.2);
      }
    }
  }

  .menu {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    transition: opacity .1s;

    .menu__items {
      right: 10vw;

      li { list-style: none; }

      > ul > li {
        padding: 0;
        margin-bottom: 30px;
        transition-delay: 0s;
      }

      a {
        line-height: 1;
        position: relative;
        font-size: 4rem;
        font-weight: bold;
        display: block;
        overflow: hidden;

        span {
          padding-bottom: 4px;
          display: block;
          color: #27005c;
          transition: color .3s;
          will-change: transform;
          transform: translateY(110%);
        }

        &:hover span {
          //color: #d434b8;
        }
      }
    }

  }

  &.is-open {
    .shape {
      transform: scale(2) !important;
      &:before {
        transform: rotate(1deg);
        width: 170vw;
        height: 170vw;
      }

      &:after {
        width: 100vw;
        height: 10vh;
      }
    }

    .menu {
      opacity: 1;
      visibility: visible;
      .menu__items {
        > ul > li {
          line-height: 1;
          > a span {
            transform: translateY(0);
            transition: transform .3s ease .5s;
          }

          @for $i from 1 through 7 {
            &:nth-child(#{$i}) > a span {
              $length: $i + $i;
              transition-delay: .3 + $length * .1s;
            }
          }
        }
      }
    }
  }
}

// Only mobile
@include breakpoint-max(medium) {
  #header {
    .navigation {
      .navigation__burger {

        &:before {
          top: -20px;
          right: -10px;
          height: 90px;
          width: 90px;
        }
      }
    }

    .menu {
      padding-top: 100px;

      .menu__items {
        overflow: scroll;
        height: 100%;

        .sub-nav {
          display: none;
          padding: 0;
          margin-top: 20px;

          a { font-size: 2.5rem; }
          a span { transform: translateY(0); }
        }
      }

    }

    &.is-open {
      .navigation .navigation__burger:before {
        width: 170vh;
        height: 170vh;
      }
    }
  }
}

@include breakpoint(small) {
}

@include breakpoint(medium) {
  #header {

    .logo {
      padding: gutter(1) 35px 35px gutter(1);
      transition: background .3s;
    }

    &.logo-bkg {
      .logo {
      }
    }

    .menu {
      .menu__items {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        li {
          position: relative;

          .sub-nav { visibility: hidden; }

          &.sub-nav--single > a:before, &.sub-nav--trigger:before {
            content: "";
            position: absolute;
            width: 0;
            height: 10px;
            background-color: #ff00cc;
            top: 50%;
            margin-top: 5px;
            border-radius: 30px;
            transform: translateY(-50%);
            transition: width .5s;
          }

          &.sub-nav--single > a {
            position: relative;
            display: inline-block;
            &:before {
              left: 0;
            }
          }

          &.sub-nav--trigger {
            &:before {
              right: 100%;
            }
          }

          &.sub-nav--single:hover a {
            &:before { width: 100%; }
          }

          &.sub-nav--trigger:hover {
            z-index: 9;
            &:before { width: 80px; }

            .sub-nav {
              visibility: visible;
              li a span {
                transform: translateY(0);
              }
            }
          }
        }

        a {
          font-size: 6rem;
          span {padding: 0 10px;}
        }

        .sub-nav {
          position: absolute;
          right: 100%;
          top: 0;
          margin: 0;
          padding: 20px 90px 0 0;

          li a {
            font-size: 3rem;
            line-height: 1.2;
            white-space: nowrap;
            span {
              transition: transform .3s ease, color .3s;
            }

            &:hover span {
              color: #ff00cc;
            }
          }
        }
      }
    }

    &.is-open {
      .navigation .navigation__burger:before {
        width: 190vw;
        height: 190vw;
      }
    }
  }
}

