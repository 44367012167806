//=====================================
// #FORM
//=====================================
input {
  border: solid 1px black;
  border-radius: 0px;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:placeholder-shown) {
    background-color: #fbfbfb;
  }

  &[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed;
  }
}

.input--hold {
  margin-bottom: 20px;
}

textarea {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  transition: background .3s, border .5s;

  &:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb;
  }

  &:not(:empty) {
    background-color: #fbfbfb;
  }
}

select {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px 40px 15px 15px;
  outline: none;
  -webkit-appearance: none; /* for box shadows to show on iOS */
}

//=====================================
// #FORM-TYPES
//=====================================
.input__hold--fancy {
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative;

  label {
    margin-top: 12px;
    margin-bottom: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    position: absolute;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    color: #333;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 50px;

    span {
      padding-left: 10px;
      font-size: 1rem;
      display: inline-block;
      color: #c7192e;
    }
  }

  input, textarea, select {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: #333;
  }

  input {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }

    &[type=file] {
      border: 0;
      padding-top: 0;
      width: 100%;
      display: flex;
    }
  }

  textarea {

    &:focus ~ label, &.active--input ~ label {
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      font-size: 14px;
    }
  }

  select {
    width: 100%;
    font-size: 18px;
    transition: border .5s;
  }

  &.active--input select {
    border-bottom: 2px solid white;

    ~ label {
      color: #333;
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px;
    }
  }
}

.form--white .input__hold--fancy {
  label { color: white; }
  input, textarea, select {
    border-bottom-color: white;
    color: white;
  }
}

#firstName { display: none; }

// Honey pot

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0;
}

.checkbox {
  display: flex;

  label {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.3;

    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px;

    &::before, &::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block;
    }

    &::before {
      height: 16px;
      width: 16px;
      border: 1px solid palette(brand, purple);
      left: 0;
      background-color: palette(brand, purple);
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px;
    }

    &::after {
      color: white;
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px;
    }
  }

  input[type="checkbox"] {

    + label::after {
      content: none;
    }

    &:checked + label {
      &:before {
        background-color: palette(brand, purple);
        border-color: palette(brand, purple);
      }

      &::after {
        content: "";
      }
    }

    &:focus + label::before {
      outline: rgb(59, 153, 252) auto 5px;
    }

    &:disabled ~ label {
      &:before, &:after { opacity: .5 }
    }
  }

  &.error {
    label::before, label::after {
      animation: shake .5s ease-in-out forwards;
      //transform: scale(1.2);
    }
  }
}

.form-hold {
  position: relative;

  .form-container {transition: opacity .3s;}

  .form-complete {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition-delay: .5s;
  }

  &--complete {

    .form-container {
      opacity: 0;
      visibility: hidden;
    }

    .form-complete {
      opacity: 1;
      visibility: visible;
    }
  }

}

//=====================================
// #FORM-COLORS
//=====================================
.input--success {
  border-color: palette(green);
}

.input--error {
  border-color: palette(red);
}

