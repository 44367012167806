.title-shape {
  display: inline-block;
  padding: 30px 40px 0;

  h1, h2, h3 { margin-bottom: 0; }

  &--1 { clip-path: polygon(11% 4%, 96% 25%, 100% 101%, 0% 101%); }
  &--2 { clip-path: polygon(4% 25%, 89% 4%, 100% 101%, 0% 101%); }

  &--purple {
    background-color: palette(brand, purple);
    color: white;
  }

  &--white {
    background-color: white;
    color: palette(brand, red);
  }

  &--grey {
    background-color: palette(grey, light);
    color: palette(brand, red);
  }
}

@include breakpoint(medium) {
  .title-shape {
    padding: 40px 50px 10px;
  }
}