.burger-menu {
  position: relative;
  z-index: 3;
  width: 40px;
  height: 30px;
  margin: 0 auto;
  color: #60635E;
  text-transform: uppercase;
}

.burger-click-region {
  cursor: pointer;
  height: 30px;

  &:hover .burger-menu-piece { border-color: #d434b8; }
}

$menu-animation-duration: 400ms;
$menu-animation-timing: ease-out;

.burger-menu-piece {
  border-radius: 25px;
  display: block;
  position: absolute;
  width: 40px;
  border-top: 4px solid #27005c;
  transform-origin: 50% 50%;
  transition: transform $menu-animation-duration $menu-animation-timing, border-color .3s;

  &:nth-child(1) {
    top: 0;
    transition: transform .3s, border-color .3s;
  }

  &:nth-child(2) {
    top: 12px;
    opacity: 1;
    transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2, border-color .3s;
  }

  &:nth-child(3) {
    top: 24px;
  }



  .active & {

    &:nth-child(1) {
      animation: burger-open-top $menu-animation-duration $menu-animation-timing forwards;
    }

    &:nth-child(2) {
      opacity: 0;
      transition: transform $menu-animation-duration $menu-animation-timing, opacity 0ms linear $menu-animation-duration / 2;
    }

    &:nth-child(3) {
      animation: burger-open-bot $menu-animation-duration $menu-animation-timing forwards;
    }
  }

  .closing & {
    &:nth-child(1) {
      animation: burger-close-top $menu-animation-duration $menu-animation-timing forwards;
    }

    &:nth-child(3) {
      animation: burger-close-bot $menu-animation-duration $menu-animation-timing forwards;
    }
  }
}

@keyframes burger-open-top {
  50% {
    transform: translate3d(0, 12px, 0);
  }
  100% {
    transform: translate3d(0, 12px, 0) rotate(45deg);
  }
}

@keyframes burger-open-bot {
  50% {
    transform: translate3d(0, -12px, 0);
  }
  100% {
    transform: translate3d(0, -12px, 0) rotate(-45deg);
  }
}

@keyframes burger-close-top {
  0% {
    transform: translate3d(0, 12px, 0) rotate(45deg);
  }
  50% {
    transform: translate3d(0, 12px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes burger-close-bot {
  0% {
    transform: translate3d(0, -12px, 0) rotate(-45deg);
  }
  50% {
    transform: translate3d(0, -12px, 0) rotate(0deg);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
