//=====================================
// #COOKIE
//=====================================
#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  padding: 60px gutter(1) 10px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-clip-path: polygon(27% 0, 100% 14%, 100% 100%, 0 100%, 0 12%);
  clip-path: polygon(27% 0, 100% 14%, 100% 100%, 0 100%, 0 12%);

  p { color: #333; }
}

@include breakpoint(medium) {
  #cookie-message {
    width: 500px;
    padding: 60px 20px 20px 60px;

    -webkit-clip-path: polygon(16% 0, 100% 13%, 100% 100%, 6% 100%, 0 30%);
    clip-path: polygon(16% 0, 100% 13%, 100% 100%, 6% 100%, 0 30%);
  }
}