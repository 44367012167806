/* quicksand-regular - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/quicksand/quicksand-v7-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Quicksand Regular"), local("Quicksand-Regular"), url("/fonts/quicksand/quicksand-v7-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/quicksand/quicksand-v7-latin-regular.woff2") format("woff2"), url("/fonts/quicksand/quicksand-v7-latin-regular.woff") format("woff"), url("/fonts/quicksand/quicksand-v7-latin-regular.ttf") format("truetype"), url("/fonts/quicksand/quicksand-v7-latin-regular.svg#Quicksand") format("svg");
  /* Legacy iOS */ }

/* quicksand-500 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/quicksand/quicksand-v7-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Quicksand Medium"), local("Quicksand-Medium"), url("/fonts/quicksand/quicksand-v7-latin-500.eot?#iefix") format("embedded-opentype"), url("/fonts/quicksand/quicksand-v7-latin-500.woff2") format("woff2"), url("/fonts/quicksand/quicksand-v7-latin-500.woff") format("woff"), url("/fonts/quicksand/quicksand-v7-latin-500.ttf") format("truetype"), url("/fonts/quicksand/quicksand-v7-latin-500.svg#Quicksand") format("svg");
  /* Legacy iOS */ }

/* quicksand-700 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/quicksand/quicksand-v7-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Quicksand Bold"), local("Quicksand-Bold"), url("/fonts/quicksand/quicksand-v7-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/quicksand/quicksand-v7-latin-700.woff2") format("woff2"), url("/fonts/quicksand/quicksand-v7-latin-700.woff") format("woff"), url("/fonts/quicksand/quicksand-v7-latin-700.ttf") format("truetype"), url("/fonts/quicksand/quicksand-v7-latin-700.svg#Quicksand") format("svg");
  /* Legacy iOS */ }

/* quicksand-300 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/quicksand/quicksand-v7-latin-300.eot");
  /* IE9 Compat Modes */
  src: local("Quicksand Light"), local("Quicksand-Light"), url("/fonts/quicksand/quicksand-v7-latin-300.eot?#iefix") format("embedded-opentype"), url("/fonts/quicksand/quicksand-v7-latin-300.woff2") format("woff2"), url("/fonts/quicksand/quicksand-v7-latin-300.woff") format("woff"), url("/fonts/quicksand/quicksand-v7-latin-300.ttf") format("truetype"), url("/fonts/quicksand/quicksand-v7-latin-300.svg#Quicksand") format("svg");
  /* Legacy iOS */ }

/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/oswald/oswald-v16-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Oswald Bold"), local("Oswald-Bold"), url("/fonts/oswald/oswald-v16-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/oswald/oswald-v16-latin-700.woff2") format("woff2"), url("/fonts/oswald/oswald-v16-latin-700.woff") format("woff"), url("/fonts/oswald/oswald-v16-latin-700.ttf") format("truetype"), url("/fonts/oswald/oswald-v16-latin-700.svg#Oswald") format("svg");
  /* Legacy iOS */ }

body.debug:after {
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  text-transform: uppercase;
  padding: 17px 25px;
  position: fixed;
  font-size: 15px;
  font-weight: 700;
  bottom: 45px;
  right: 0;
  z-index: 999; }
  @media (max-width: 767px) {
    body.debug:after {
      content: "x-small"; } }
  @media (min-width: x-small) {
    body.debug:after {
      content: "x-small"; } }
  @media (min-width: 768px) {
    body.debug:after {
      content: "small"; } }
  @media (min-width: 992px) {
    body.debug:after {
      content: "medium"; } }
  @media (min-width: 1260px) {
    body.debug:after {
      content: "large"; } }

.bg-cover {
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center; }

@media (max-width: 992px) {
  .no-bg-sm {
    background-image: none !important; } }

main, .wrapper, #footer .socials {
  *zoom: 1; }
  main:before, .wrapper:before, #footer .socials:before, main:after, .wrapper:after, #footer .socials:after {
    content: " ";
    display: table; }
  main:after, .wrapper:after, #footer .socials:after {
    clear: both; }

.clear {
  clear: both; }
  .clear:after {
    content: "";
    display: table;
    clear: both; }

.is-hidden {
  display: none; }

@media (min-width: 768px) {
  .is-hidden--sm {
    display: none !important; } }

@media (min-width: 992px) {
  .is-hidden--md {
    display: none !important; } }

.is-visible {
  display: block; }

.is-visible--md {
  display: none; }
  @media (min-width: 992px) {
    .is-visible--md {
      display: block !important; } }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

textarea {
  resize: none; }

html {
  height: 100%; }

html, body {
  max-width: 100%;
  font-size: 55% !important;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100% !important;
  font-family: "Quicksand", sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #333333;
  background: white; }
  @media (min-width: 1260px) {
    html, body {
      font-size: 62.5% !important;
      /* Normalise font size so that 1rem = 10px, 2rem = 20px etc. */ } }

body {
  position: relative; }
  body.fix {
    overflow: hidden; }

main {
  position: relative;
  z-index: 1; }

h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 25px; }

h1, .h1 {
  font-size: 6rem; }
  @media (min-width: 768px) {
    h1, .h1 {
      font-size: 6.4rem; } }

h2, .h2 {
  font-size: 4rem; }
  @media (min-width: 768px) {
    h2, .h2 {
      font-size: 4.8rem; } }

h3, .h3 {
  font-size: 2.8rem; }

h4, .h4 {
  font-size: 2.4rem; }

p, .p {
  font-family: "Quicksand", sans-serif;
  font-size: 1.8rem;
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 300; }

.small > p,
.small > .p,
.small {
  font-size: 1.6rem; }

.big > p,
.big > .p,
.big {
  font-size: 2.2rem; }

a, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: #ff00cc;
  font-weight: 400;
  transition: color .3s; }
  a:hover, .link--fake:hover {
    color: #27005c; }

.link {
  font-weight: bold;
  font-size: 1.8rem; }

.link--icon .icon {
  margin-left: 10px; }

.link--icon-left .icon {
  margin-right: 10px; }

.link--white {
  color: white; }
  .link--white:hover {
    color: #ff00cc; }

.link--black {
  color: #27005c; }
  .link--black:hover {
    color: #ff00cc; }
    .link--black:hover .icon svg {
      fill: #ff00cc; }

.link--red {
  color: #ea3457; }
  .link--red:hover {
    color: #27005c; }
    .link--red:hover .icon svg {
      fill: #27005c; }

ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px; }
  ul.list--unstyled, ul.ul--row, ol.list--unstyled, ol.ul--row {
    list-style: none;
    margin: 0;
    padding: 0; }
    ul.list--unstyled li, ul.ul--row li, ol.list--unstyled li, ol.ul--row li {
      padding: 0; }
  ul.ul--row li, ol.ul--row li {
    float: left; }

ul {
  list-style: disc; }

ol {
  list-style: decimal; }

li {
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 300;
  color: #333333;
  padding-bottom: 15px; }

.color--white {
  color: white; }
  .color--white > * {
    color: white; }

.color--red {
  color: #ea3457; }
  .color--red > * {
    color: #ea3457; }

.bold, strong {
  font-weight: bold; }
  .bold > *, strong > * {
    font-weight: bold; }

.italic, em {
  font-style: italic; }
  .italic > *, em > * {
    font-style: italic; }

.icon {
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
  vertical-align: middle;
  background-size: contain; }
  .icon > svg {
    display: block; }
  .icon path, .icon polygon {
    transition: fill .3s; }

.icon--white path {
  fill: white; }

.icon--black path, .icon--black polygon {
  fill: black !important; }

.icon--black path, .icon--black polygon, .icon--black rect, .icon--black circle {
  fill: black; }

.icon--black-text path, .icon--black-text polygon, .icon--black-text rect, .icon--black-text circle {
  fill: #333333; }

.icon--black-hover path, .icon--black-hover polygon, .icon--black-hover rect, .icon--black-hover circle {
  fill: #989896; }

.icon--black-footer path, .icon--black-footer polygon, .icon--black-footer rect, .icon--black-footer circle {
  fill: #2b2b2b; }

.icon--light path, .icon--light polygon, .icon--light rect, .icon--light circle {
  fill: #ededed; }

.icon--grey path, .icon--grey polygon, .icon--grey rect, .icon--grey circle {
  fill: #5f5f5f; }

.icon--dark path, .icon--dark polygon, .icon--dark rect, .icon--dark circle {
  fill: #d7d7d7; }

.icon--blue path, .icon--blue polygon, .icon--blue rect, .icon--blue circle {
  fill: #32a1b2; }

.icon--blue-hover path, .icon--blue-hover polygon, .icon--blue-hover rect, .icon--blue-hover circle {
  fill: #2b8e9d; }

.icon--green path, .icon--green polygon, .icon--green rect, .icon--green circle {
  fill: #32b292; }

.icon--red path, .icon--red polygon, .icon--red rect, .icon--red circle {
  fill: #ed2f2f; }

.icon--red-error path, .icon--red-error polygon, .icon--red-error rect, .icon--red-error circle {
  fill: #EE3D52; }

.icon--brand-pink path, .icon--brand-pink polygon, .icon--brand-pink rect, .icon--brand-pink circle {
  fill: #ff00cc; }

.icon--brand-purple path, .icon--brand-purple polygon, .icon--brand-purple rect, .icon--brand-purple circle {
  fill: #27005c; }

.icon--brand-red path, .icon--brand-red polygon, .icon--brand-red rect, .icon--brand-red circle {
  fill: #ea3457; }

.icon--brand-blue path, .icon--brand-blue polygon, .icon--brand-blue rect, .icon--brand-blue circle {
  fill: #00e7ff; }

.icon--brand-yellow path, .icon--brand-yellow polygon, .icon--brand-yellow rect, .icon--brand-yellow circle {
  fill: #ffe600; }

.icon--20,
.icon--20 > svg {
  width: 20px;
  height: 20px; }

.icon--25,
.icon--25 > svg {
  width: 25px;
  height: 25px; }

.icon--30,
.icon--30 > svg {
  width: 30px;
  height: 30px; }

.icon--40,
.icon--40 > svg {
  width: 40px;
  height: 40px; }

.icon--60,
.icon--60 > svg {
  width: 60px;
  height: 60px; }

.icon--service {
  display: block;
  margin-right: auto;
  margin-left: auto; }
  .icon--service,
  .icon--service > svg {
    width: 100px;
    height: 100px; }
  @media (min-width: 1260px) {
    .icon--service,
    .icon--service > svg {
      width: 130px;
      height: 130px; } }

.icon--logo,
.icon--logo > svg {
  width: 90px;
  height: 46px; }
  .icon--logo path,
  .icon--logo > svg path {
    fill: white; }
  @media (min-width: 992px) {
    .icon--logo,
    .icon--logo > svg {
      width: 150px;
      height: 77px; } }

.icon--footer-logo,
.icon--footer-logo > svg {
  width: 120px;
  height: 62px; }

.icon--social,
.icon--social > svg {
  width: 60px;
  height: 60px; }

.icon--social svg > *.bkg {
  fill: #27005c; }

.icon--social svg > *.social {
  fill: white; }

.icon--social svg {
  overflow: visible; }

.icon--social polygon, .icon--social path, .icon--social circle {
  transition: transform .3s;
  transform-origin: center; }

.icon--social:hover polygon {
  transform: scale(1.2) rotate(30deg); }

.icon--social:hover path, .icon--social:hover circle {
  transform: scale(0.8); }

a:hover .icon--facebook svg > *.bkg {
  fill: #3b5998; }

a:hover .icon--insta svg > *.bkg {
  fill: #c32aa3; }

input {
  border: solid 1px black;
  border-radius: 0px;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */
  transition: background .3s, border .5s; }
  input:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb; }
  input:not(:placeholder-shown) {
    background-color: #fbfbfb; }
  input[disabled] {
    background-color: #f7f7f8;
    cursor: not-allowed; }

.input--hold {
  margin-bottom: 20px; }

textarea {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px;
  outline: none;
  transition: background .3s, border .5s; }
  textarea:focus {
    border-color: #a1a1a1;
    background-color: #fbfbfb; }
  textarea:not(:empty) {
    background-color: #fbfbfb; }

select {
  border: solid 1px black;
  border-radius: 0;
  font-size: 1.5rem;
  line-height: 1.5;
  padding: 15px 40px 15px 15px;
  outline: none;
  -webkit-appearance: none;
  /* for box shadows to show on iOS */ }

.input__hold--fancy {
  margin-top: 24px;
  margin-bottom: 20px;
  position: relative; }
  .input__hold--fancy label {
    margin-top: 12px;
    margin-bottom: 0;
    -webkit-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    position: absolute;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    color: #333;
    pointer-events: none;
    font-weight: 400;
    font-size: 18px;
    left: 0;
    top: 50px; }
    .input__hold--fancy label span {
      padding-left: 10px;
      font-size: 1rem;
      display: inline-block;
      color: #c7192e; }
  .input__hold--fancy input, .input__hold--fancy textarea, .input__hold--fancy select {
    width: 100%;
    border: none;
    border-bottom: 2px solid black;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;
    color: #333; }
  .input__hold--fancy input:focus ~ label, .input__hold--fancy input.active--input ~ label {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
    letter-spacing: 1px;
    font-size: 14px; }
  .input__hold--fancy input[type=file] {
    border: 0;
    padding-top: 0;
    width: 100%;
    display: flex; }
  .input__hold--fancy textarea:focus ~ label, .input__hold--fancy textarea.active--input ~ label {
    -webkit-transform: translateY(-75px);
    transform: translateY(-75px);
    font-size: 14px; }
  .input__hold--fancy select {
    width: 100%;
    font-size: 18px;
    transition: border .5s; }
  .input__hold--fancy.active--input select {
    border-bottom: 2px solid white; }
    .input__hold--fancy.active--input select ~ label {
      color: #333;
      -webkit-transform: translateY(-75px);
      transform: translateY(-75px);
      letter-spacing: 1px;
      font-size: 14px; }

.form--white .input__hold--fancy label {
  color: white; }

.form--white .input__hold--fancy input, .form--white .input__hold--fancy textarea, .form--white .input__hold--fancy select {
  border-bottom-color: white;
  color: white; }

#firstName {
  display: none; }

input[type=checkbox] {
  -webkit-appearance: checkbox;
  margin: 0; }

.checkbox {
  display: flex; }
  .checkbox label {
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    line-height: 1.3;
    /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
    padding-left: 30px; }
    .checkbox label::before, .checkbox label::after {
      position: absolute;
      content: "";
      /*Needed for the line-height to take effect*/
      display: inline-block; }
    .checkbox label::before {
      height: 16px;
      width: 16px;
      border: 1px solid #27005c;
      left: 0;
      background-color: #27005c;
      /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
       *to vertically center it.
       */
      top: 3px; }
    .checkbox label::after {
      color: white;
      height: 5px;
      width: 9px;
      border-left: 2px solid;
      border-bottom: 2px solid;
      transform: rotate(-45deg);
      left: 4px;
      top: 7px; }
  .checkbox input[type="checkbox"] + label::after {
    content: none; }
  .checkbox input[type="checkbox"]:checked + label:before {
    background-color: #27005c;
    border-color: #27005c; }
  .checkbox input[type="checkbox"]:checked + label::after {
    content: ""; }
  .checkbox input[type="checkbox"]:focus + label::before {
    outline: #3b99fc auto 5px; }
  .checkbox input[type="checkbox"]:disabled ~ label:before, .checkbox input[type="checkbox"]:disabled ~ label:after {
    opacity: .5; }
  .checkbox.error label::before, .checkbox.error label::after {
    animation: shake .5s ease-in-out forwards; }

.form-hold {
  position: relative; }
  .form-hold .form-container {
    transition: opacity .3s; }
  .form-hold .form-complete {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    opacity: 0;
    visibility: hidden;
    transition-delay: .5s; }
  .form-hold--complete .form-container {
    opacity: 0;
    visibility: hidden; }
  .form-hold--complete .form-complete {
    opacity: 1;
    visibility: visible; }

.input--success {
  border-color: #32b292; }

.input--error {
  border-color: #ed2f2f; }

.btn {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  color: #ff00cc;
  outline: none;
  background-color: white;
  border: none;
  padding: 20px 30px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .btn svg path {
    fill: #27005c; }
  .btn:hover, .btn:focus {
    background-color: #ff00cc;
    color: white; }
    .btn:hover svg path, .btn:focus svg path {
      fill: white; }

.btn--full {
  width: 100%; }

.btn--r {
  float: right; }

.btn--icon {
  display: inline-flex;
  align-items: center; }
  .btn--icon .icon {
    margin-left: 10px; }
  .btn--icon--large .icon {
    margin-left: 25px; }

.btn--path-1 {
  clip-path: polygon(15% 5%, 95% 5%, 99% 71%, 85% 95%, 5% 95%, 1% 29%); }
  .btn--path-1:hover {
    clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 50%); }

.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px; }

.btn--large {
  font-size: 2.5rem;
  padding: 30px 40px; }

.btn--white {
  border-color: white;
  color: white; }

.btn--pink {
  background-color: #ff00cc;
  color: white; }
  .btn--pink:hover, .btn--pink:focus {
    background-color: #27005c;
    color: white; }

.btn--red-text {
  color: #ea3457; }
  .btn--red-text:hover, .btn--red-text:focus {
    background-color: #27005c;
    color: white; }

.btn--red-fill {
  background-color: #ea3457;
  color: white; }
  .btn--red-fill:hover, .btn--red-fill:focus {
    background-color: #27005c;
    color: white; }

#cookie-message {
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 9999;
  padding: 60px 1.33334rem 10px;
  background-color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  -webkit-clip-path: polygon(27% 0, 100% 14%, 100% 100%, 0 100%, 0 12%);
  clip-path: polygon(27% 0, 100% 14%, 100% 100%, 0 100%, 0 12%); }
  #cookie-message p {
    color: #333; }

@media (min-width: 992px) {
  #cookie-message {
    width: 500px;
    padding: 60px 20px 20px 60px;
    -webkit-clip-path: polygon(16% 0, 100% 13%, 100% 100%, 6% 100%, 0 30%);
    clip-path: polygon(16% 0, 100% 13%, 100% 100%, 6% 100%, 0 30%); } }

.chunk {
  display: block;
  clear: both;
  background-color: white;
  position: relative;
  overflow: hidden; }
  .chunk:after {
    content: "";
    display: table;
    clear: both; }
  .chunk--o-n {
    overflow: visible; }

/*
BUILDING: Island colors from the pallet in config/colours
*/
.chunk--black {
  background-color: black; }

.chunk--black-text {
  background-color: #333333; }

.chunk--black-hover {
  background-color: #989896; }

.chunk--black-footer {
  background-color: #2b2b2b; }

.chunk--grey-light {
  background-color: #ededed; }

.chunk--grey {
  background-color: #5f5f5f; }

.chunk--grey-dark {
  background-color: #d7d7d7; }

.chunk--blue {
  background-color: #32a1b2; }

.chunk--blue-hover {
  background-color: #2b8e9d; }

.chunk--green {
  background-color: #32b292; }

.chunk--red {
  background-color: #ed2f2f; }

.chunk--red-error {
  background-color: #EE3D52; }

.chunk--brand-pink {
  background-color: #ff00cc; }

.chunk--brand-purple {
  background-color: #27005c; }

.chunk--brand-red {
  background-color: #ea3457; }

.chunk--brand-blue {
  background-color: #00e7ff; }

.chunk--brand-yellow {
  background-color: #ffe600; }

.chunk--white {
  background-color: white; }

.chunk--black {
  background-color: black; }
  .chunk--black h1, .chunk--black h2, .chunk--black p {
    color: white; }

.chunk--transparent {
  background-color: transparent;
  overflow: visible; }

.chunk--bkg-red {
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("/dist/img/icons/red-bkg.svg"); }

.chunk--blue {
  background-color: #00e7ff; }

.chunk--split-white-blue,
.chunk--split-blue-white,
.chunk--split-purple-white,
.chunk--split-white-purple,
.chunk--split-white-grey,
.chunk--split-grey-white,
.chunk--split-white-yellow,
.chunk--split-yellow-white {
  position: relative; }
  .chunk--split-white-blue:before,
  .chunk--split-blue-white:before,
  .chunk--split-purple-white:before,
  .chunk--split-white-purple:before,
  .chunk--split-white-grey:before,
  .chunk--split-grey-white:before,
  .chunk--split-white-yellow:before,
  .chunk--split-yellow-white:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }

.chunk--split-white-yellow:before {
  background-color: #ffe600;
  clip-path: polygon(101% 0, 0% 101%, 101% 101%); }

.chunk--split-yellow-white {
  background-color: #ffe600; }
  .chunk--split-yellow-white:before {
    background-color: white;
    clip-path: polygon(101% 101%, 0 0, 0 101%); }
  .chunk--split-yellow-white.cs-mod {
    z-index: 2;
    background: transparent; }
    .chunk--split-yellow-white.cs-mod:before {
      background-color: #ffe600;
      clip-path: polygon(101% 101%, -1% -1%, 101% -1%); }
    @media (min-width: 768px) {
      .chunk--split-yellow-white.cs-mod {
        margin-bottom: -109px; } }
    @media (min-width: 992px) {
      .chunk--split-yellow-white.cs-mod {
        margin-bottom: -129px; } }
    @media (min-width: 1260px) {
      .chunk--split-yellow-white.cs-mod {
        margin-bottom: -137px; } }

.chunk--split-white-blue:before {
  background-color: #00e7ff;
  clip-path: polygon(101% 0, 0% 101%, 101% 101%); }

.chunk--split-blue-white {
  background-color: #00e7ff; }
  .chunk--split-blue-white:before {
    background-color: white;
    clip-path: polygon(101% 101%, 0 0, 0 101%); }

.chunk--split-white-purple:before {
  background-color: #27005c;
  clip-path: polygon(101% 0, 0% 101%, 101% 101%); }

.chunk--split-purple-white {
  background-color: #27005c; }
  .chunk--split-purple-white:before {
    background-color: white;
    clip-path: polygon(101% 101%, 0 0, 0 101%); }

.chunk--split-white-grey:before {
  background-color: #ededed;
  clip-path: polygon(101% 0, 0% 101%, 101% 101%); }

.chunk--split-grey-white {
  background-color: #ededed; }
  .chunk--split-grey-white:before {
    background-color: white;
    clip-path: polygon(101% 101%, 0 0, 0 101%); }

/*
  NOTE

	This file should only contain css related to the grid
	just widths, margins and vertical spacing,
	Any other styling, such as background colours, should be moved to a different
	selector. Nount wrong with 4/5 classes on a single element.
	<div class="g__col gm__1-2 gt__1-3 gd__1-4 cta-box"></div>
	Ideally, if you can, move the cta styling to a child element, leaving the class
	with only grid properties. Oh so reusable, oh so portable
*/
.g {
  width: 100%;
  lost-flex-container: row; }
  .g > * {
    width: 100%; }

.g--ng > * {
  width: 50%;
  flex-basis: 50%; }

.g__ac {
  align-items: center; }

.g__1-2 {
  lost-column: 1/2; }

/*
  Footer grid modification
*/
.g--gutter .g__1-2 {
  lost-column: 1/2; }

.g--gutter .g__1-3 {
  lost-column: 1/3 3 1.33334rem flex; }

.g--gutter .g__2-3 {
  lost-column: 2/3 3 1.33334rem flex; }

.g__ma {
  margin-left: auto !important;
  margin-right: auto !important; }

@media (min-width: 768px) {
  .gs__1-3 {
    lost-column: 1/3; }
  .gs__2-3 {
    lost-column: 2/3; }
  .g--gutter .gs__1-2 {
    lost-column: 1/2 2 1.33334rem; }
  .g--gutter-lg .gs__1-2 {
    lost-column: 1/2 2 8rem flex; }
  .g--gutter-lg .gs__1-3 {
    lost-column: 1/3 3 8rem flex; } }

@media (min-width: 992px) {
  .gm__ac {
    align-items: center; }
  .g--gutter .gm__1-2 {
    lost-column: 1/2 2 2.66667rem; }
  .g--gutter .gm__1-3 {
    lost-column: 1/3 3 2.66667rem; }
  .g--gutter-lg .gm__1-2 {
    lost-column: 1/2 2 8rem; }
  .g--gutter-lg .gm__1-3 {
    lost-column: 1/3 3 8rem; }
  .g--gutter-lg .gm__1-5 {
    lost-column: 1/5 5 8rem flex; }
  .gm__1-2 {
    lost-column: 1/2; } }

@media (min-width: 1260px) {
  .g--gutter .gl__1-3 {
    lost-column: 1/3 3 2.66667rem; }
  .g--gutter .gl__1-4 {
    lost-column: 1/4 4 2.66667rem flex; }
  .g--gutter-lg .gl__1-4 {
    lost-column: 1/4 4 8rem flex; }
  .gl__1-3 {
    lost-column: 1/3 0 0 flex; }
  .g--row-1 .gl__mod:first-of-type {
    lost-column: 4/10 10 2.66667rem; }
  .g--row-1 .gl__mod:last-of-type {
    lost-column: 6/10 10 2.66667rem; }
  .g--row-2 .gl__mod:first-of-type {
    lost-column: 6/10 10 2.66667rem; }
  .g--row-2 .gl__mod:last-of-type {
    lost-column: 4/10 10 2.66667rem; } }

.wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1.33334rem; }
  @media (min-width: 768px) {
    .wrapper {
      width: 720px; } }
  @media (min-width: 992px) {
    .wrapper {
      width: 940px; } }
  @media (min-width: 1260px) {
    .wrapper {
      width: 1260px; } }

.wrapper--tight {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1.33334rem; }
  @media (min-width: 992px) {
    .wrapper--tight {
      width: 740px; } }
  @media (min-width: 1260px) {
    .wrapper--tight {
      width: 960px; } }

@media (min-width: 992px) {
  .wrapper--x-tight {
    width: 780px; } }

@media (min-width: 768px) {
  .wrapper--full {
    width: 100%; } }

#header {
  position: fixed;
  z-index: 9;
  width: 100%; }
  #header .logo {
    position: relative;
    z-index: 999;
    display: inline-block;
    padding: 20px 30px 30px 1.33334rem;
    transition: background .3s;
    clip-path: polygon(85% 0, 100% 55%, 55% 100%, 0 85%, 0 0); }
    #header .logo img {
      max-width: 60px; }
  #header.logo-bkg .logo, #header.is-open .logo {
    background: white; }
    #header.logo-bkg .logo svg path, #header.is-open .logo svg path {
      fill: #1D1D1B; }
  #header .shape, #header .navigation {
    position: absolute; }
  #header .shape {
    top: 0;
    right: 0;
    z-index: 998;
    width: 40px;
    transition: transform .3s; }
    #header .shape:before, #header .shape:after {
      content: "";
      display: block;
      position: absolute;
      transition: all .5s ease-in-out; }
    #header .shape:before {
      top: -20px;
      right: -5px;
      height: 90px;
      width: 90px;
      background-image: url("/dist/img/icons/ieffects-polygon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center; }
    #header .shape:after {
      top: 0;
      right: 0;
      height: 50px;
      width: 50px;
      background-color: white; }
  #header .navigation {
    z-index: 1000;
    top: 1.33334rem;
    right: 1.33334rem;
    display: flex; }
    #header .navigation .navigation__burger {
      position: relative;
      color: white; }
    #header .navigation:hover ~ .shape {
      transform: scale(1.2); }
  #header .menu {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    height: 100vh;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    transition: opacity .1s; }
    #header .menu .menu__items {
      right: 10vw; }
      #header .menu .menu__items li {
        list-style: none; }
      #header .menu .menu__items > ul > li {
        padding: 0;
        margin-bottom: 30px;
        transition-delay: 0s; }
      #header .menu .menu__items a {
        line-height: 1;
        position: relative;
        font-size: 4rem;
        font-weight: bold;
        display: block;
        overflow: hidden; }
        #header .menu .menu__items a span {
          padding-bottom: 4px;
          display: block;
          color: #27005c;
          transition: color .3s;
          will-change: transform;
          transform: translateY(110%); }
  #header.is-open .shape {
    transform: scale(2) !important; }
    #header.is-open .shape:before {
      transform: rotate(1deg);
      width: 170vw;
      height: 170vw; }
    #header.is-open .shape:after {
      width: 100vw;
      height: 10vh; }
  #header.is-open .menu {
    opacity: 1;
    visibility: visible; }
    #header.is-open .menu .menu__items > ul > li {
      line-height: 1; }
      #header.is-open .menu .menu__items > ul > li > a span {
        transform: translateY(0);
        transition: transform .3s ease .5s; }
      #header.is-open .menu .menu__items > ul > li:nth-child(1) > a span {
        transition-delay: 0.5s; }
      #header.is-open .menu .menu__items > ul > li:nth-child(2) > a span {
        transition-delay: 0.7s; }
      #header.is-open .menu .menu__items > ul > li:nth-child(3) > a span {
        transition-delay: 0.9s; }
      #header.is-open .menu .menu__items > ul > li:nth-child(4) > a span {
        transition-delay: 1.1s; }
      #header.is-open .menu .menu__items > ul > li:nth-child(5) > a span {
        transition-delay: 1.3s; }
      #header.is-open .menu .menu__items > ul > li:nth-child(6) > a span {
        transition-delay: 1.5s; }
      #header.is-open .menu .menu__items > ul > li:nth-child(7) > a span {
        transition-delay: 1.7s; }

@media (max-width: 992px) {
  #header .navigation .navigation__burger:before {
    top: -20px;
    right: -10px;
    height: 90px;
    width: 90px; }
  #header .menu {
    padding-top: 100px; }
    #header .menu .menu__items {
      overflow: scroll;
      height: 100%; }
      #header .menu .menu__items .sub-nav {
        display: none;
        padding: 0;
        margin-top: 20px; }
        #header .menu .menu__items .sub-nav a {
          font-size: 2.5rem; }
        #header .menu .menu__items .sub-nav a span {
          transform: translateY(0); }
  #header.is-open .navigation .navigation__burger:before {
    width: 170vh;
    height: 170vh; } }

@media (min-width: 992px) {
  #header .logo {
    padding: 1.33334rem 35px 35px 1.33334rem;
    transition: background .3s; }
  #header .menu .menu__items {
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
    #header .menu .menu__items li {
      position: relative; }
      #header .menu .menu__items li .sub-nav {
        visibility: hidden; }
      #header .menu .menu__items li.sub-nav--single > a:before, #header .menu .menu__items li.sub-nav--trigger:before {
        content: "";
        position: absolute;
        width: 0;
        height: 10px;
        background-color: #ff00cc;
        top: 50%;
        margin-top: 5px;
        border-radius: 30px;
        transform: translateY(-50%);
        transition: width .5s; }
      #header .menu .menu__items li.sub-nav--single > a {
        position: relative;
        display: inline-block; }
        #header .menu .menu__items li.sub-nav--single > a:before {
          left: 0; }
      #header .menu .menu__items li.sub-nav--trigger:before {
        right: 100%; }
      #header .menu .menu__items li.sub-nav--single:hover a:before {
        width: 100%; }
      #header .menu .menu__items li.sub-nav--trigger:hover {
        z-index: 9; }
        #header .menu .menu__items li.sub-nav--trigger:hover:before {
          width: 80px; }
        #header .menu .menu__items li.sub-nav--trigger:hover .sub-nav {
          visibility: visible; }
          #header .menu .menu__items li.sub-nav--trigger:hover .sub-nav li a span {
            transform: translateY(0); }
    #header .menu .menu__items a {
      font-size: 6rem; }
      #header .menu .menu__items a span {
        padding: 0 10px; }
    #header .menu .menu__items .sub-nav {
      position: absolute;
      right: 100%;
      top: 0;
      margin: 0;
      padding: 20px 90px 0 0; }
      #header .menu .menu__items .sub-nav li a {
        font-size: 3rem;
        line-height: 1.2;
        white-space: nowrap; }
        #header .menu .menu__items .sub-nav li a span {
          transition: transform .3s ease, color .3s; }
        #header .menu .menu__items .sub-nav li a:hover span {
          color: #ff00cc; }
  #header.is-open .navigation .navigation__burger:before {
    width: 190vw;
    height: 190vw; } }

#footer .chunk--brand-purple {
  padding: 1.33334rem 0; }
  #footer .chunk--brand-purple a, #footer .chunk--brand-purple .copy {
    color: white; }
  #footer .chunk--brand-purple .p {
    margin-bottom: 0; }

#footer .socials, #footer .logo-links {
  list-style: none;
  margin: 0;
  padding: 0; }
  #footer .socials li, #footer .logo-links li {
    padding: 0;
    margin-bottom: 20px;
    text-align: center; }
    #footer .socials li a, #footer .logo-links li a {
      display: block; }

#footer .socials-hold {
  text-align: center; }

#footer .socials {
  display: inline-block; }
  #footer .socials li {
    float: left;
    margin-right: 30px;
    margin-bottom: 0; }
    #footer .socials li:last-of-type {
      margin-right: 0; }

#footer .copy, #footer .contact {
  display: inline-block; }

#footer .contact {
  float: right; }

#footer .contact a {
  text-decoration: none;
  font-weight: bold; }

@media (min-width: 768px) {
  #footer .socials, #footer .logo-links {
    display: flex;
    align-items: center; }
    #footer .socials li, #footer .logo-links li {
      float: left;
      margin-right: 30px;
      margin-bottom: 0; }
      #footer .socials li:last-of-type, #footer .logo-links li:last-of-type {
        margin-right: 0; }
  #footer .socials {
    justify-content: flex-end; } }

@media (min-width: 992px) {
  #footer .socials li, #footer .logo-links li {
    margin-right: 45px; }
    #footer .socials li:last-of-type, #footer .logo-links li:last-of-type {
      margin-right: 0; } }

.hero {
  position: relative;
  display: block;
  clear: both; }
  .hero .tagline {
    font-weight: bold;
    font-size: 2.2rem;
    color: white; }

.hero--short {
  padding-top: 90px;
  padding-bottom: 40px;
  background-color: #27005c; }
  .hero--short .hero__inner {
    padding: 40px 1.33334rem; }
  .hero--short h1, .hero--short .h1 {
    font-size: 5rem; }
  .hero--short:before {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: white;
    clip-path: polygon(101% 0, 0% 101%, 101% 101%); }

.hero--image {
  overflow: hidden;
  width: 100%;
  padding-top: 90px;
  padding-bottom: 40px;
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center; }
  .hero--image .hero__inner {
    position: relative;
    z-index: 3;
    padding: 20px 0; }
  .hero--image h1, .hero--image .h1 {
    font-size: 5rem;
    color: white; }
  .hero--image:before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); }
  .hero--image:after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: white;
    clip-path: polygon(25% 87%, 100% 36%, 100% 100%, 0 100%, 0 0); }
  .hero--image-large .hero__inner {
    padding: 80px 0 20px; }
  .hero--image-no {
    background-color: #ea3457; }
    .hero--image-no:before {
      content: none; }

.hero--full {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center; }
  .hero--full:before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); }
  .hero--full:after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 115px;
    background-color: white;
    clip-path: polygon(75% 87%, 100% 0, 100% 100%, 0 100%, 0 37%); }
  .hero--full .hero__inner {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 0 1.33334rem; }
    .hero--full .hero__inner h1, .hero--full .hero__inner .h1, .hero--full .hero__inner p {
      line-height: 1.2;
      color: white; }
    .hero--full .hero__inner p {
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 30px; }
    .hero--full .hero__inner h1, .hero--full .hero__inner .h1 {
      font-size: 3.8rem; }

.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .video-container video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;
    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;
    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.hero--bkg-red {
  background-color: #ea3457; }

@media (min-width: 768px) {
  .hero--full .hero__inner p {
    font-size: 2rem;
    margin-bottom: 30px; }
  .hero--full .hero__inner h1, .hero--full .hero__inner .h1 {
    font-size: 8rem; }
  .video-container {
    display: block; } }

@media (min-width: 992px) {
  .hero--short {
    padding-top: 100px;
    padding-bottom: 90px; }
    .hero--short h1, .hero--short .h1 {
      font-size: 7rem; }
    .hero--short .hero__inner {
      padding: 40px 1.33334rem; }
    .hero--short:before {
      content: "";
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 90px;
      background-color: white;
      clip-path: polygon(101% 0, 0% 101%, 101% 101%); }
  .hero--image {
    padding-top: 100px;
    padding-bottom: 170px; }
    .hero--image .hero__inner {
      padding: 50px 0 10px; }
    .hero--image h1, .hero--image .h1 {
      font-size: 7rem; }
      .hero--image h1.large, .hero--image .h1.large {
        font-size: 9rem; }
    .hero--image:after {
      height: 170px; }
    .hero--image-large .hero__inner {
      padding: 280px 0 10px; }
  .hero--full:after {
    height: 170px; }
  .hero--full .hero__inner {
    padding-left: 50px; }
    .hero--full .hero__inner p {
      font-size: 2.4rem;
      margin-bottom: 30px; }
    .hero--full .hero__inner h1, .hero--full .hero__inner .h1 {
      font-size: 9rem; } }

@media (min-width: 1260px) {
  .hero--full .hero__inner {
    padding-left: 180px; } }

.pagination {
  text-align: center; }
  .pagination a, .pagination span {
    padding: 0 2px;
    margin: 0 8px;
    color: black;
    font-weight: 300; }
    @media (min-width: 992px) {
      .pagination a, .pagination span {
        margin: 0 8px; } }
  .pagination .icon {
    padding: 0;
    opacity: .5;
    transition: opacity .5s; }
    .pagination .icon:hover {
      opacity: 1; }
  .pagination .previous {
    margin-right: 10px; }
  .pagination .next {
    margin-left: 10px; }
  @media (min-width: 992px) {
    .pagination .previous {
      margin-right: 20px; }
    .pagination .next {
      margin-left: 20px; } }
  .pagination span.current {
    border-bottom: 2px solid #ff00cc; }

.burger-menu {
  position: relative;
  z-index: 3;
  width: 40px;
  height: 30px;
  margin: 0 auto;
  color: #60635E;
  text-transform: uppercase; }

.burger-click-region {
  cursor: pointer;
  height: 30px; }
  .burger-click-region:hover .burger-menu-piece {
    border-color: #d434b8; }

.burger-menu-piece {
  border-radius: 25px;
  display: block;
  position: absolute;
  width: 40px;
  border-top: 4px solid #27005c;
  transform-origin: 50% 50%;
  transition: transform 400ms ease-out, border-color 0.3s; }
  .burger-menu-piece:nth-child(1) {
    top: 0;
    transition: transform .3s, border-color .3s; }
  .burger-menu-piece:nth-child(2) {
    top: 12px;
    opacity: 1;
    transition: transform 400ms ease-out, opacity 0ms linear 200ms, border-color 0.3s; }
  .burger-menu-piece:nth-child(3) {
    top: 24px; }
  .active .burger-menu-piece:nth-child(1) {
    animation: burger-open-top 400ms ease-out forwards; }
  .active .burger-menu-piece:nth-child(2) {
    opacity: 0;
    transition: transform 400ms ease-out, opacity 0ms linear 200ms; }
  .active .burger-menu-piece:nth-child(3) {
    animation: burger-open-bot 400ms ease-out forwards; }
  .closing .burger-menu-piece:nth-child(1) {
    animation: burger-close-top 400ms ease-out forwards; }
  .closing .burger-menu-piece:nth-child(3) {
    animation: burger-close-bot 400ms ease-out forwards; }

@keyframes burger-open-top {
  50% {
    transform: translate3d(0, 12px, 0); }
  100% {
    transform: translate3d(0, 12px, 0) rotate(45deg); } }

@keyframes burger-open-bot {
  50% {
    transform: translate3d(0, -12px, 0); }
  100% {
    transform: translate3d(0, -12px, 0) rotate(-45deg); } }

@keyframes burger-close-top {
  0% {
    transform: translate3d(0, 12px, 0) rotate(45deg); }
  50% {
    transform: translate3d(0, 12px, 0) rotate(0deg); }
  100% {
    transform: translate3d(0, 0, 0); } }

@keyframes burger-close-bot {
  0% {
    transform: translate3d(0, -12px, 0) rotate(-45deg); }
  50% {
    transform: translate3d(0, -12px, 0) rotate(0deg); }
  100% {
    transform: translate3d(0, 0, 0); } }

.title-shape {
  display: inline-block;
  padding: 30px 40px 0; }
  .title-shape h1, .title-shape h2, .title-shape h3 {
    margin-bottom: 0; }
  .title-shape--1 {
    clip-path: polygon(11% 4%, 96% 25%, 100% 101%, 0% 101%); }
  .title-shape--2 {
    clip-path: polygon(4% 25%, 89% 4%, 100% 101%, 0% 101%); }
  .title-shape--purple {
    background-color: #27005c;
    color: white; }
  .title-shape--white {
    background-color: white;
    color: #ea3457; }
  .title-shape--grey {
    background-color: #ededed;
    color: #ea3457; }

@media (min-width: 992px) {
  .title-shape {
    padding: 40px 50px 10px; } }

.tile {
  position: relative; }

.tile--client {
  margin-bottom: 35px; }

.tile--image {
  overflow: hidden;
  display: block;
  margin-bottom: 1.33334rem; }
  .tile--image .tile__hold {
    position: relative;
    background-size: 100%;
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center;
    height: 300px; }
    .tile--image .tile__hold:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0; }
  .tile--image .tile__text {
    position: absolute;
    padding: 20px; }
    .tile--image .tile__text h2, .tile--image .tile__text h3 {
      margin-bottom: 0; }
  .tile--image .tile__shape {
    position: absolute;
    z-index: 998;
    width: 90px;
    height: 90px;
    transition: transform .3s; }
    .tile--image .tile__shape .icon {
      position: relative;
      z-index: 2;
      margin: 0 auto;
      display: block; }
      .tile--image .tile__shape .icon path {
        fill: #27005c; }
    .tile--image .tile__shape:before {
      content: "";
      display: block;
      position: absolute;
      height: 90px;
      width: 90px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      transition: transform .3s; }
  .tile--image-1 .tile__hold:before {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(125, 185, 232, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  .tile--image-1 .tile__text {
    top: 0; }
  .tile--image-1 .tile__shape {
    bottom: 0;
    right: 0; }
    .tile--image-1 .tile__shape .icon {
      margin-top: 35px;
      padding-left: 10px; }
    .tile--image-1 .tile__shape:before {
      bottom: 0;
      right: 0;
      background-image: url("/dist/img/icons/ieffects-polygon-flip.svg"); }
  .tile--image-2 .tile__hold:before {
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }
  .tile--image-2 .tile__text {
    bottom: 0; }
  .tile--image-2 .tile__shape {
    top: 0;
    right: 0; }
    .tile--image-2 .tile__shape .icon {
      margin-top: 25px;
      padding-left: 10px; }
    .tile--image-2 .tile__shape:before {
      top: 0;
      right: 0;
      background-image: url("/dist/img/icons/ieffects-polygon.svg"); }
  .tile--image:hover .tile__shape .icon path {
    fill: #ff00cc; }
  .tile--image:hover .tile__shape:before {
    transform: scale(1.2); }

.tile--cs {
  display: block;
  margin-bottom: 40px; }
  .tile--cs .img {
    font-size: 0;
    position: relative; }
    .tile--cs .img:before {
      content: "";
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background-color: white;
      clip-path: polygon(35% 44%, 59% 13%, 101% 37%, 101% 101%, -1% 101%, 0 37%, 27% 2%);
      transition: background-color .3s, clip-path .3s; }
  .tile--cs h2, .tile--cs h3, .tile--cs .h2, .tile--cs .h3 {
    color: #ea3457; }
  .tile--cs .inner {
    padding: 0 20px 20px;
    color: #333;
    background-color: white;
    transition: background-color .3s, color .3s; }
  .tile--cs .btn {
    background-color: #ea3457;
    color: white; }
  .tile--cs:hover .img:before {
    clip-path: polygon(50% 0, 75% 0, 101% 0, 101% 101%, -1% 101%, 0 0, 25% 0); }
  .tile--cs:hover .inner, .tile--cs:hover .img:before {
    background-color: #ea3457; }
  .tile--cs:hover .inner, .tile--cs:hover h2, .tile--cs:hover h3, .tile--cs:hover .h2, .tile--cs:hover .h3 {
    color: white; }
  .tile--cs:hover .btn {
    color: #ea3457;
    background-color: white;
    clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 50%); }
    .tile--cs:hover .btn svg path {
      fill: #ea3457; }

.tile--news {
  display: block;
  margin-bottom: 40px; }
  .tile--news h2, .tile--news h3, .tile--news .h2, .tile--news .h3 {
    color: #ea3457; }
  .tile--news .inner {
    padding: 20px;
    color: #333;
    background-color: #ededed;
    transition: background-color .3s; }
  .tile--news .btn {
    background-color: white;
    color: #ea3457; }
  .tile--news:hover .inner {
    background-color: white; }
  .tile--news:hover .btn {
    color: white;
    background-color: #ea3457;
    clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 50%); }
    .tile--news:hover .btn svg path {
      fill: white; }

@media (min-width: 768px) {
  .tile--image .tile__text {
    transition: padding .3s; }
    .tile--image .tile__text:before {
      content: "";
      position: absolute;
      height: 5px;
      border-radius: 25px;
      width: 0;
      background-color: #ff00cc;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      transition: width .3s; }
  .tile--image:hover .tile__text {
    padding-left: 75px; }
    .tile--image:hover .tile__text:before {
      width: 45px; } }

@media (min-width: 992px) {
  .tile--client {
    margin-bottom: 80px; }
  .tile--image {
    display: block;
    margin-bottom: 2.66667rem; }
    .tile--image .tile__hold {
      height: 400px; }
  .tile--cs .inner {
    padding: 0 40px 60px; } }

.cta--split .cta__half {
  text-align: center; }
  .cta--split .cta__half .inner {
    padding: 55px 1.33334rem; }

@media (min-width: 768px) {
  .cta--plain .inner {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 992px) {
  .cta--split {
    display: flex; }
    .cta--split .cta__half {
      flex: 1;
      position: relative; }
      .cta--split .cta__half .inner {
        max-width: 550px;
        margin-right: auto;
        margin-left: auto; } }

.body .h3 {
  margin: 35px 0 20px; }

.body .image, .body .video {
  margin: 10px 0 20px; }

.body .video iframe {
  width: 100%;
  height: 320px; }

@media (min-width: 992px) {
  .body .video iframe {
    height: 450px; } }

.video--large iframe {
  width: 100%;
  height: 320px; }

@media (min-width: 992px) {
  .video--large iframe {
    height: 650px; } }

.image {
  font-size: 0; }
  .image figcaption {
    background-color: #ea3457;
    padding: 10px 1.33334rem;
    color: white;
    font-size: 1.6rem;
    font-weight: bold; }

.pusher {
  padding-left: 35px;
  position: relative; }
  .pusher:before {
    content: "";
    height: 3px;
    border-radius: 25px;
    width: 25px;
    background-color: #ea3457;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%); }

.img--shape {
  margin: 0 20px 40px;
  position: relative; }
  .img--shape img {
    position: relative; }
  .img--shape:before {
    content: "";
    position: absolute;
    width: 110%;
    height: 110%;
    top: 50%;
    left: 50%;
    background-color: #ffe600;
    clip-path: polygon(15% 0, 95% 5%, 100% 90%, 20% 100%, 0 70%);
    transform: translateX(-50%) translateY(-50%); }
  .img--shape-2:before {
    background-color: #ea3457;
    clip-path: polygon(9% 10%, 94% 0, 100% 100%, 8% 93%, 0 58%); }
  @media (min-width: 992px) {
    .img--shape {
      margin: 0; } }

.stats {
  margin-bottom: 40px; }
  .stats:last-of-type {
    margin-bottom: 0; }
  .stats span {
    display: block;
    font-weight: bold;
    margin-bottom: 10px; }
    .stats span:last-of-type {
      margin-bottom: 0; }
  .stats .x-big-text, .stats .big-text {
    font-family: "Oswald", sans-serif; }
  .stats .x-big-text {
    font-size: 7rem; }
  .stats .big-text {
    font-size: 4.2rem; }
  .stats .text {
    font-size: 2.6rem; }
  @media (min-width: 768px) {
    .stats {
      margin-bottom: 0; } }

.icon-hover {
  margin-bottom: 40px; }
  .icon-hover a .icon, .icon-hover a .text {
    transition: transform .3s;
    display: block;
    -webkit-backface-visibility: hidden; }
  .icon-hover a .text {
    margin-top: 30px; }
  .icon-hover a:hover .text {
    transform: translateZ(0) scale(1.2); }
  .icon-hover a:hover .icon {
    transform: scale(1.1); }
  @media (min-width: 992px) {
    .icon-hover {
      margin-bottom: 0; } }

.quote div, .quote p {
  position: relative;
  z-index: 1; }

.quote:before, .quote:after {
  content: "";
  position: absolute;
  top: -10px;
  width: 40px;
  height: 27px;
  background-repeat: no-repeat; }

.quote:before {
  left: 10px;
  background-image: url("/dist/img/icons/quote-left.svg"); }

.quote:after {
  right: 10px;
  background-image: url("/dist/img/icons/quote-right.svg"); }

.quote .x-big p {
  font-size: 2.2rem; }
  @media (min-width: 768px) {
    .quote .x-big p {
      font-size: 3rem; } }

@media (min-width: 768px) {
  .quote:before, .quote:after {
    top: -10px;
    width: 80px;
    height: 50px; }
  .quote:before {
    left: -80px; }
  .quote:after {
    right: -80px; } }

.extra-cs .half {
  background-size: 100%;
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center; }

.extra-cs a {
  display: block;
  position: relative;
  color: #ea3457;
  font-size: 3rem; }
  .extra-cs a path {
    fill: white; }
  .extra-cs a:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: black;
    opacity: .6;
    transition: opacity .3s; }
  .extra-cs a:hover {
    color: white; }
    .extra-cs a:hover:before {
      opacity: .35; }
    .extra-cs a:hover .link-block {
      background-color: #ea3457; }

.extra-cs .inner {
  position: relative;
  padding: 150px 1.33334rem 30px; }

.extra-cs .link-block {
  display: inline-block;
  padding: 10px;
  transition: background-color .3s; }

@media (min-width: 768px) {
  .extra-cs {
    display: flex; }
    .extra-cs .half {
      flex: 1; }
      .extra-cs .half:last-of-type {
        text-align: right; }
    .extra-cs .inner {
      position: relative;
      padding: 360px 4rem 50px; } }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  z-index: 1;
  display: block;
  height: 50px;
  width: 50px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  @media (min-width: 768px) {
    .slick-prev,
    .slick-next {
      height: 65px;
      width: 65px; } }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -10px; }
  @media (min-width: 768px) {
    .slick-prev {
      left: -17px; }
      [dir="rtl"] .slick-prev {
        left: auto;
        right: -17px; }
      .slick-prev:before {
        content: ""; }
        [dir="rtl"] .slick-prev:before {
          content: ""; } }

.slick-next {
  right: -10px; }
  @media (min-width: 768px) {
    .slick-next {
      right: -17px; }
      [dir="rtl"] .slick-next {
        left: -17px;
        right: auto; }
      .slick-next:before {
        content: ""; }
        [dir="rtl"] .slick-next:before {
          content: ""; } }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 20px;
        height: 20px;
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

@media (min-width: 768px) {
  .slick-list {
    padding-top: 40px !important;
    padding-bottom: 40px !important; } }

@media (min-width: 992px) {
  .slick-list {
    padding-top: 53px !important;
    padding-bottom: 53px !important; } }

@media (min-width: 1260px) {
  .slick-list {
    padding-top: 73px !important;
    padding-bottom: 73px !important; } }

.gallery .polygon, .gallery .arrow {
  transition: fill .3s; }

.gallery .polygon {
  fill: #FFE600; }

.gallery .arrow {
  fill: #323232; }

.gallery .slick-arrow:hover .polygon {
  fill: #27005c; }

.gallery .slick-arrow:hover .arrow {
  fill: white; }

@media (min-width: 768px) {
  .gallery img {
    transition: transform .3s;
    border-left: 10px solid white;
    border-right: 10px solid white; }
  .gallery .slick-current img {
    transform: scale(1.5); } }

.fr {
  float: right; }

.a11y {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.bump {
  margin-bottom: 20px; }

.bumper {
  margin-bottom: 60px; }

.tt-u {
  text-transform: uppercase; }

.bold {
  font-weight: 800; }

.normal {
  font-weight: 400; }

em, .italic {
  font-style: italic; }

.td-u {
  text-decoration: underline; }

.ta-c {
  text-align: center; }
  .ta-c > * {
    text-align: center; }

.ta-l {
  text-align: left; }

.ta-r {
  text-align: right; }

.td-n {
  text-decoration: none; }

.img--responsive {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto; }

.img--fit {
  width: 100%; }

.sr-text {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden; }

.frame {
  width: 100%;
  height: 250px; }
  @media (min-width: 768px) {
    .frame {
      height: 395px; } }
  @media (min-width: 992px) {
    .frame {
      height: 520px; } }
  @media (min-width: 1260px) {
    .frame {
      height: 425px; } }

.spacer-fixed-top {
  margin-top: 20px; }

.spacer-fixed {
  margin-bottom: 20px; }

.nudge {
  margin-right: 10px; }

.spacer {
  margin-bottom: 8vh; }
  .spacer--large {
    margin-bottom: 8vh; }
    @media (min-width: 768px) {
      .spacer--large {
        margin-bottom: 15vh; } }
  .spacer-top {
    margin-top: 8vh; }
    .spacer-top--large {
      margin-top: 8vh; }
      @media (min-width: 768px) {
        .spacer-top--large {
          margin-top: 15vh; } }

@media (min-width: 992px) {
  .spacer--md {
    margin-bottom: 8vh; } }

@media (min-width: 992px) {
  .spacer-top--md {
    margin-top: 8vh; } }

@media print {
  .no-print {
    display: none; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hero::after,
  .hero--full::after,
  .hero--image::after,
  .hero--short::after {
    content: none !important;
    background-color: transparent !important; }
  .ie-e--chop {
    display: none; }
  .img--shape::before {
    content: none !important; }
  #header.is-open .menu {
    background-color: white !important; } }

@supports (-ms-ime-align: auto) {
  .hero::after,
  .hero--full::after,
  .hero--image::after,
  .hero--short::before {
    content: none !important;
    background-color: transparent !important; }
  .ie-e--chop {
    display: none; }
  .img--shape::before {
    content: none !important; }
  #header.is-open .menu {
    background-color: white !important; } }
