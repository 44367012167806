//=====================================
// #HEADINGS
//=====================================
h1, .h1,
h2, .h2,
h3, .h3 {
  font-family: $heading-font;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 25px;
}

h1, .h1 {
  font-size: 6rem;
  @include breakpoint(small) { font-size: $h1-size; }
}

h2, .h2 {
  font-size: 4rem;
  @include breakpoint(small) { font-size: $h2-size; }
}

h3, .h3 { font-size: $h3-size; }

h4, .h4 { font-size: $h4-size; }

//=====================================
// #TEXT
//=====================================
p, .p {
  font-family: $body-font;
  font-size: $p-size;
  line-height: 1.5;
  margin-bottom: 20px;
  font-weight: 300;
}

.small > p,
.small > .p,
.small {
  font-size: $p-size--small;
}

.big > p,
.big > .p,
.big {
  font-size: $p-size--big;
}

a, .link--fake {
  cursor: pointer;
  text-decoration: none;
  color: #ff00cc;
  font-weight: 400;
  transition: color .3s;
  &:hover { color: #27005c; }
}

.link {
  font-weight: bold;
  font-size: $p-size;
}

.link--icon .icon {
  margin-left: 10px;
}

.link--icon-left .icon {
  margin-right: 10px;
}

.link--white {
  color: white;
  &:hover { color: palette(brand, pink); }
}

.link--black {
  color: #27005c;
  &:hover {
    color: palette(brand, pink);
    .icon svg { fill: palette(brand, pink); }
  }
}

.link--red {
  color: palette(brand, red);
  &:hover {
    color: palette(brand, purple);
    .icon svg { fill: palette(brand, purple); }
  }
}

//=====================================
// #LISTS
//=====================================
ul, ol {
  margin-left: 20px;
  padding-top: 10px;
  padding-bottom: 25px;

  &.list--unstyled, &.ul--row {
    list-style: none;
    margin: 0;
    padding: 0;
    li { padding: 0; }
  }

  &.ul--row {
    li { float: left; }
  }
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li {
  font-size: $p-size;
  line-height: 1.5;
  font-weight: 300;
  color: palette(black, text);
  padding-bottom: 15px;
}

//=====================================
// #COLOURS/LINKS
//=====================================
.color--white {
  color: white;
  > * { color: white; }
}

.color--red {
  color: palette(brand, red);
  > * { color: palette(brand, red); }
}

.bold, strong {
  font-weight: bold;
  > * { font-weight: bold; }
}

.italic, em {
  font-style: italic;
  > * { font-style: italic; }
}