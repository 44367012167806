//=====================================
// #BUTTON
//=====================================
.btn {
  text-decoration: none;
  text-align: center;
  font-size: 2rem;
  line-height: 1;
  font-family: $heading-font;
  font-weight: bold;
  color: #ff00cc;
  outline: none;
  background-color: white;
  border: none;
  padding: 20px 30px;
  display: inline-block;
  cursor: pointer;
  transition: all .3s;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  svg path { fill: #27005c; }

  &:hover, &:focus {
    background-color: #ff00cc;
    color: white;
    svg path { fill: white; }
  }
}

.btn--full { width: 100%; }

.btn--r { float: right; }

.btn--icon {
  display: inline-flex;
  align-items: center;

  .icon {
    margin-left: 10px;
  }

  &--large {
    .icon {
      margin-left: 25px;
    }
  }
}

.btn--path-1 {
  // Attemept 1
  //clip-path: polygon(49% 0, 95% 0, 100% 71%, 100% 71%, 90% 100%, 5% 100%, 0 24%, 10% 0);

  clip-path: polygon(15% 5%, 95% 5%, 99% 71%, 85% 95%, 5% 95%, 1% 29%);
  &:hover {
    // Attemept 1
    //clip-path: polygon(49% 0, 100% 0, 100% 71%, 100% 71%, 100% 100%, 0 100%, 0 24%, 0 0);
    clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 50%);
  }
}

//=====================================
// #BUTTON-SIZES
//=====================================
.btn--small {
  font-size: 1.7rem;
  line-height: 2.3rem;
  padding: 7px 20px;
}

.btn--large {
  font-size: 2.5rem;
  padding: 30px 40px;
}

//=====================================
// #BUTTON-COLORS
//=====================================
.btn--white {
  border-color: white;
  color: white;
}

.btn--pink {
  background-color: #ff00cc;
  color: white;

  &:hover, &:focus {
    background-color: #27005c;
    color: white;
  }
}

.btn--red-text {
  color: palette(brand, red);

  &:hover, &:focus {
    background-color: #27005c;
    color: white;
  }
}

.btn--red-fill {
  background-color: palette(brand, red);
  color: white;

  &:hover, &:focus {
    background-color: #27005c;
    color: white;
  }
}