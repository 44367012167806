//==========================================================================
// Abstrakts (ITCSS & BEM Method) Style coding
//==========================================================================


//======================================== CONFIGS
@import '_config/colours';
@import '_config/typography';
//======================================== UTILS
@import '_utils/breakpoints';
@import '_utils/background-cover';
@import '_utils/clearfix';
@import '_utils/hidden-visible';

//======================================== BASE
@import 'base/reset';
@import 'base/html-body';
@import 'base/typography';
@import 'base/icon';
@import 'base/inputs';
@import 'base/button';
@import 'base/cookie';
@import 'base/chunk';
//======================================== LAYOUT
@import 'layout/grid'; // Grid
@import 'layout/wrapper';
@import 'layout/header';
@import 'layout/footer';
//======================================== MODULES
@import 'modules/hero';
@import 'modules/pagination';
@import 'modules/hamburger';
@import 'modules/title-shape';
@import 'modules/tile';
@import 'modules/cta';
@import 'modules/layout';
//======================================== VENDOR
@import 'vendor/slick';
@import 'vendor/slick-theme';

@import '_utils/index';
//======================================== PRINT
@import '_utils/print';

// IE10-11
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .hero::after,
  .hero--full::after,
  .hero--image::after,
  .hero--short::after {
    content: none !important;
    background-color: transparent !important;
  }

  .ie-e--chop { display: none; }
  .img--shape::before { content: none !important; }
  #header.is-open .menu { background-color: white !important; }
}

// Microsoft EdgeV13
@supports (-ms-ime-align: auto) {
  .hero::after,
  .hero--full::after,
  .hero--image::after,
  .hero--short::before {
    content: none !important;
    background-color: transparent !important;
  }

  .ie-e--chop { display: none; }
  .img--shape::before { content: none !important; }
  #header.is-open .menu { background-color: white !important; }
}
