//=====================================
// #HERO
//=====================================
.hero {
  position: relative;
  display: block; // in case we add this class to an inline elem
  clear: both;

  .tagline {
    font-weight: bold;
    font-size: 2.2rem;
    color: white;
  }
}

.hero--short {
  padding-top: 90px;
  padding-bottom: 40px;
  background-color: palette(brand, purple);

  .hero__inner {
    padding: 40px gutter(1);
  }

  h1, .h1 { font-size: 5rem }

  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: white;
    clip-path: polygon(101% 0, 0% 101%, 101% 101%);
  }
}

.hero--image {
  overflow: hidden;
  width: 100%;
  padding-top: 90px;
  padding-bottom: 40px;
  @include bg-cover();

  .hero__inner {
    position: relative;
    z-index: 3;
    padding: 20px 0;
  }

  h1, .h1 {
    font-size: 5rem;
    color: white;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40px;
    background-color: white;
    clip-path: polygon(25% 87%, 100% 36%, 100% 100%, 0 100%, 0 0);
  }

  &-large .hero__inner { padding: 80px 0 20px; }


  &-no {
    background-color: palette(brand, red);
    &:before { content: none; }
  }
}

.hero--full {
  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  @include bg-cover();

  &:before {
    content: "";
    position: absolute;
    z-index: 2;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: rgba(0,0,0, 0.4);
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 2;
    right:0;
    bottom:0;
    left:0;
    width: 100%;
    height: 115px;
    background-color: white;
    clip-path: polygon(75% 87%, 100% 0, 100% 100%, 0 100%, 0 37%);
  }

  .hero__inner {
    position: absolute;
    z-index: 2;
    top:50%;
    left:0;
    transform: translateY(-50%);
    padding: 0 gutter(1);

    h1, .h1 , p { line-height: 1.2; color: white; }

    p {
      font-size: 2.4rem;
      font-weight: 500;
      margin-bottom: 30px;
    }

    h1, .h1 {
      font-size: 3.8rem;
    }
  }
}



.video-container {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  video {
    /* Make video to at least 100% wide and tall */
    min-width: 100%;
    min-height: 100%;

    /* Setting width & height to auto prevents the browser from stretching or squishing the video */
    width: auto;
    height: auto;

    /* Center the video */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.hero--bkg-red {
  background-color: palette(brand, red);
}

@include breakpoint(small) {

  .hero--full {
    .hero__inner {

      p {
        font-size: 2rem;
        margin-bottom: 30px;
      }

      h1, .h1 { font-size: 8rem; }
    }
  }

  .video-container {
    display: block;
  }

}

@include breakpoint(medium) {

  .hero--short {
    padding-top: 100px;
    padding-bottom: 90px;

    h1, .h1 {
      font-size: 7rem;
    }

    .hero__inner { padding: 40px gutter(1); }

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 90px;
      background-color: white;
      clip-path: polygon(101% 0, 0% 101%, 101% 101%);
    }
  }

  .hero--image {
    padding-top: 100px;
    padding-bottom: 170px;

    .hero__inner { padding: 50px 0 10px; }
    h1, .h1 {
      font-size: 7rem;
      &.large { font-size: 9rem }
    }
    &:after { height: 170px; }

    &-large .hero__inner { padding: 280px 0 10px; }
  }

  .hero--full {

    &:after {
      height: 170px;
    }

    .hero__inner {
      padding-left: 50px;

      p {
        font-size: 2.4rem;
        margin-bottom: 30px;
      }

      h1, .h1 { font-size: 9rem; }
    }
  }

}
@include breakpoint(large) {
  .hero--full {
    .hero__inner { padding-left: 180px; }
  }
}
