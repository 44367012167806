//=====================================
// #HEADINGS
//=====================================
$h1-size: 6.4rem;
$h2-size: 4.8rem;
$h3-size: 2.8rem;
$h4-size: 2.4rem;

$p-size: 1.8rem;
$p-size--small: 1.6rem;
$p-size--big: 2.2rem;

$heading-font: 'Quicksand', sans-serif;
$body-font: 'Quicksand', sans-serif;
$alt-font: 'Oswald', sans-serif;

/* quicksand-regular - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/quicksand/quicksand-v7-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Quicksand Regular'), local('Quicksand-Regular'),
  url('/fonts/quicksand/quicksand-v7-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/quicksand/quicksand-v7-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/quicksand/quicksand-v7-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('/fonts/quicksand/quicksand-v7-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/quicksand/quicksand-v7-latin-regular.svg#Quicksand') format('svg'); /* Legacy iOS */
}

/* quicksand-500 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/quicksand/quicksand-v7-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Quicksand Medium'), local('Quicksand-Medium'),
  url('/fonts/quicksand/quicksand-v7-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/quicksand/quicksand-v7-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/quicksand/quicksand-v7-latin-500.woff') format('woff'), /* Modern Browsers */
  url('/fonts/quicksand/quicksand-v7-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/quicksand/quicksand-v7-latin-500.svg#Quicksand') format('svg'); /* Legacy iOS */
}

/* quicksand-700 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/quicksand/quicksand-v7-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Quicksand Bold'), local('Quicksand-Bold'),
  url('/fonts/quicksand/quicksand-v7-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/quicksand/quicksand-v7-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/quicksand/quicksand-v7-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/fonts/quicksand/quicksand-v7-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/quicksand/quicksand-v7-latin-700.svg#Quicksand') format('svg'); /* Legacy iOS */
}

/* quicksand-300 - latin */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/quicksand/quicksand-v7-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Quicksand Light'), local('Quicksand-Light'),
  url('/fonts/quicksand/quicksand-v7-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/quicksand/quicksand-v7-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/quicksand/quicksand-v7-latin-300.woff') format('woff'), /* Modern Browsers */
  url('/fonts/quicksand/quicksand-v7-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/quicksand/quicksand-v7-latin-300.svg#Quicksand') format('svg'); /* Legacy iOS */
}

/* oswald-700 - latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/oswald/oswald-v16-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Oswald Bold'), local('Oswald-Bold'),
  url('/fonts/oswald/oswald-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('/fonts/oswald/oswald-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('/fonts/oswald/oswald-v16-latin-700.woff') format('woff'), /* Modern Browsers */
  url('/fonts/oswald/oswald-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('/fonts/oswald/oswald-v16-latin-700.svg#Oswald') format('svg'); /* Legacy iOS */
}