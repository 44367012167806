// ==========================================================================
// #FOOTER
// ==========================================================================
#footer {

  .chunk--brand-purple {
    padding: gutter(1) 0;
    a, .copy { color: white; }
    .p { margin-bottom: 0; }
  }

  .socials, .logo-links {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 0;
      margin-bottom: 20px;
      text-align: center;

      a { display: block; }
    }
  }

  .logo-links {
  }

  .socials-hold { text-align: center; }

  .socials {
    display: inline-block;
    li {
      float: left;
      margin-right: 30px;
      margin-bottom: 0;
      &:last-of-type { margin-right: 0; }
    }

    @extend %clearfix;
  }

  .copy, .contact { display: inline-block; }
  .contact { float: right; }
  .contact a {
    text-decoration: none;
    font-weight: bold;
  }

}

@include breakpoint(small) {
  #footer {

    .socials, .logo-links {
      display: flex;
      align-items: center;

      li {
        float: left;
        margin-right: 30px;
        margin-bottom: 0;
        &:last-of-type { margin-right: 0; }
      }
    }

    .socials { justify-content: flex-end }
  }
}

@include breakpoint(medium) {
  #footer {
    .socials, .logo-links {
      li {
        margin-right: 45px;
        &:last-of-type { margin-right: 0; }
      }
    }
  }
}

@include breakpoint(large) {}