//=====================================
// #STRUTURE
//=====================================
.wrapper {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 gutter(1);

  @include breakpoint(small) {
    width: 720px;
  }

  @include breakpoint(medium) {
    width: 940px;
  }

  @include breakpoint(large) {
    width: 1260px;
  }

  @extend %clearfix;
}

.wrapper--tight {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 gutter(1);

  @include breakpoint(medium) {
    width: 740px;
  }

  @include breakpoint(large) {
    width: 960px;
  }
}

.wrapper--x-tight {
  @include breakpoint(medium) {
    width: 780px;
  }
}

.wrapper--full {
  @include breakpoint(small) {
    width: 100%;
  }
}