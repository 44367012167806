//=====================================
// #CTA
//=====================================
.cta {
  .inner {
  }
}

.cta--plain {
}

.cta--split {
  .cta__half {
    text-align: center;
    .inner { padding: 55px gutter(1); }
  }
}

@include breakpoint-max(small) {
}

@include breakpoint(small) {
  .cta--plain {
    .inner {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

@include breakpoint(medium) {

  .cta--split {
    display: flex;
    .cta__half {
      flex: 1;
      position: relative;

      .inner {
        max-width: 550px;
        margin-right: auto;
        margin-left: auto;
        //position: absolute;
        //top: 50%;
        //left: 0;
        //right: 0;
        //transform: translateY(-50%);
      }
    }
  }
}

@include breakpoint(large) {
}