//=====================================
// #HERO
//=====================================
.tile {
  position: relative;
}

.tile--client {
  margin-bottom: 35px;
}

.tile--image {
  overflow: hidden;
  display: block;
  margin-bottom: gutter(1);

  .tile__hold {
    position: relative;
    @include bg-cover();
    height: 300px;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  .tile__text {
    position: absolute;
    padding: 20px;

    h2, h3 { margin-bottom: 0; }
  }

  .tile__shape {
    position: absolute;
    z-index: 998;
    width: 90px;
    height: 90px;
    transition: transform .3s;

    .icon {
      position: relative;
      z-index: 2;
      margin: 0 auto;
      display: block;
      path { fill: palette(brand, purple); }
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      height: 90px;
      width: 90px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      transition: transform .3s;
    }
  }

  &-1 {
    .tile__hold {
      &:before {
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(125, 185, 232, 0) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(125, 185, 232, 0) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(125, 185, 232, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }
    .tile__text { top: 0; }

    .tile__shape {
      bottom: 0;
      right: 0;

      .icon {
        margin-top: 35px;
        padding-left: 10px;
      }

      &:before {
        bottom: 0;
        right: 0;
        background-image: url('/dist/img/icons/ieffects-polygon-flip.svg');
      }
    }
  }

  &-2 {

    .tile__hold {
      &:before {
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      }
    }
    .tile__text { bottom: 0; }

    .tile__shape {
      top: 0;
      right: 0;

      .icon {
        margin-top: 25px;
        padding-left: 10px;
      }

      &:before {
        top: 0;
        right: 0;
        background-image: url('/dist/img/icons/ieffects-polygon.svg');
      }
    }
  }

  &:hover {
    .tile__shape {
      .icon path { fill: palette(brand, pink); }

      &:before {
        transform: scale(1.2);
      }
    }
  }
}

.tile--cs {
  display: block;
  margin-bottom: 40px;

  .img {
    font-size: 0;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      z-index: 2;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background-color: white;
      clip-path: polygon(35% 44%, 59% 13%, 101% 37%, 101% 101%, -1% 101%, 0 37%, 27% 2%);
      transition: background-color .3s, clip-path .3s;
    }
  }

  h2, h3, .h2, .h3 { color: palette(brand, red); }

  .inner {
    padding: 0 20px 20px;
    color: #333;
    background-color: white;
    transition: background-color .3s, color .3s;
  }

  .btn {
    background-color: palette(brand, red);
    color: white;
  }

  &:hover {
    .img:before { clip-path: polygon(50% 0, 75% 0, 101% 0, 101% 101%, -1% 101%, 0 0, 25% 0); }
    .inner, .img:before { background-color: palette(brand, red); }
    .inner, h2, h3, .h2, .h3 { color: white; }
    .btn {
      color: palette(brand, red);
      background-color: white;
      clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 50%);
      svg path { fill: palette(brand, red); }
    }
  }
}

.tile--news {
  display: block;
  margin-bottom: 40px;

  h2, h3, .h2, .h3 { color: palette(brand, red); }

  .inner {
    padding: 20px;
    color: #333;
    background-color: palette(grey, light);
    transition: background-color .3s;
  }

  .btn {
    background-color: white;
    color: palette(brand, red);
  }

  &:hover {
    .inner { background-color: white; }
    .btn {
      color: white;
      background-color: palette(brand, red);
      clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 50%);
      svg path { fill: white; }
    }
  }
}

@include breakpoint(small) {
  .tile--image {

    .tile__text {
      transition: padding .3s;

      &:before {
        content: "";
        position: absolute;
        height: 5px;
        border-radius: 25px;
        width: 0;
        background-color: palette(brand, pink);
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
        transition: width .3s;
      }
    }

    &:hover {
      .tile__text {
        padding-left: 75px;
        &:before { width: 45px; }
      }
    }
  }
}

@include breakpoint(medium) {

  .tile--client {
    margin-bottom: 80px;
  }

  .tile--image {
    display: block;
    margin-bottom: gutter(2);

    .tile__hold {
      height: 400px;
    }
  }

  .tile--cs {

    .inner { padding: 0 40px 60px; }
  }
}

@include breakpoint(large) {
}