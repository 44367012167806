// ==========================================================================
// #CHUNK
// ==========================================================================

.chunk {
  @include clearfix();
  display: block; // in case we add this class to an inline elem
  clear: both;
  background-color: white;
  position: relative;
  overflow: hidden;

  &--o-n { overflow: visible; }
}

/*
BUILDING: Island colors from the pallet in config/colours
*/
@each $color,
$shades in $palettes {
  $colorName: '--#{$color}';
  @each $shade,
  $value in $shades {
    $shadeModifier: if($shade==base, '', '-#{$shade}');
    @if $shade==base {
      $shadeModifier: '';
    } @else {
      $shadeModifier: '-#{$shade}';
    }
    .chunk#{$colorName}#{$shadeModifier} { background-color: palette($color, $shade); }
  }
}

.chunk--white { background-color: white; }

.chunk--black {
  background-color: palette(black, base);
  h1, h2, p { color: white; }
}

.chunk--transparent {
  background-color: transparent;
  overflow: visible;
}

.chunk--bkg-red {
  @include bg-cover();
  background-image: url('/dist/img/icons/red-bkg.svg');
}

.chunk--blue { background-color: #00e7ff; }

.chunk--split-white-blue,
.chunk--split-blue-white,
.chunk--split-purple-white,
.chunk--split-white-purple,
.chunk--split-white-grey,
.chunk--split-grey-white,
.chunk--split-white-yellow,
.chunk--split-yellow-white {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.chunk--split-white-yellow {
  &:before {
    background-color: palette(brand, yellow);
    clip-path: polygon(101% 0, 0% 101%, 101% 101%);
  }
}

.chunk--split-yellow-white {
  background-color: palette(brand, yellow);
  &:before {
    background-color: white;
    clip-path: polygon(101% 101%, 0 0, 0 101%);
  }

  &.cs-mod {
    z-index: 2;
    background: transparent;


    &:before {
      background-color: palette(brand, yellow);
      clip-path: polygon(101% 101%, -1% -1%, 101% -1%);
    }

    @include breakpoint(small) { margin-bottom: -109px;}
    @include breakpoint(medium) { margin-bottom: -129px;}
    @include breakpoint(large) { margin-bottom: -137px;}
  }
}

.chunk--split-white-blue {
  &:before {
    background-color: #00e7ff;
    clip-path: polygon(101% 0, 0% 101%, 101% 101%);
  }
}

.chunk--split-blue-white {
  background-color: #00e7ff;
  &:before {
    background-color: white;
    clip-path: polygon(101% 101%, 0 0, 0 101%);
  }
}

.chunk--split-white-purple {
  &:before {
    background-color: palette(brand, purple);
    clip-path: polygon(101% 0, 0% 101%, 101% 101%);
  }
}

.chunk--split-purple-white {
  background-color: palette(brand, purple);
  &:before {
    background-color: white;
    clip-path: polygon(101% 101%, 0 0, 0 101%);
  }
}

.chunk--split-white-grey {
  &:before {
    background-color: palette(grey, light);
    clip-path: polygon(101% 0, 0% 101%, 101% 101%);
  }
}

.chunk--split-grey-white {
  background-color: palette(grey, light);

  &:before {
    background-color: white;
    clip-path: polygon(101% 101%, 0 0, 0 101%);
  }
}